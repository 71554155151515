import React from 'react';
import { Redirect } from '@reach/router';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import slugify from 'slugify';
import {
  Box,
  CapabilitiesCarousel,
  Heading,
  L2Hero,
  ResponsiveContext,
  SecondaryNav,
  ServicesPanel,
  ServicesPanelAccordion,
} from '../ui';
import htmlSerializer from '../prismic/htmlSerializer';
import Layout from '../layout/primary';

const CapabilitiesHeading = styled(Heading)`
  text-transform: uppercase;
`;

function segmentBackground(index) {
  return index % 2 === 0 ? 'white' : 'light-3';
}

export const query = graphql`
  query ServiceQuery($uid: String!) {
    prismic {
      doc: service_area(uid: $uid, lang: "en-us") {
        _meta {
          uid
        }
        meta_description
        meta_title
        hero {
          ... on PRISMIC_Service_hero {
            heading
            text
            image
            body {
              ... on PRISMIC_Service_heroBodyMarket_hero {
                primary {
                  heading1
                  text1
                  image1
                  market {
                    ... on PRISMIC_Market {
                      _meta {
                        uid
                      }
                    }
                  }
                }
              }
            }
          }
        }
        segments {
          segment {
            ... on PRISMIC_Service_segment {
              title
              text
              cta_title
              cta_url {
                ... on PRISMIC__Document {
                  _meta {
                    type
                  }
                }
                ... on PRISMIC__ExternalLink {
                  target
                  _linkType
                  url
                }
              }
              proofs {
                keyword
                proof {
                  ... on PRISMIC_Publication {
                    type
                    title
                    date
                    summary
                    image
                    _meta {
                      uid
                    }
                  }
                }
              }

              rep {
                ... on PRISMIC_Services__representative {
                  name
                  phone_number
                  photo
                  quote
                  cta_text
                  cta_link1
                }
              }
              body {
                ... on PRISMIC_Service_segmentBodyMarket_segment {
                  primary {
                    priority
                    title1
                    text1
                    rep1 {
                      ... on PRISMIC_Services__representative {
                        name
                        phone_number
                        photo
                        quote
                        cta_text
                        cta_link1
                      }
                    }
                    market {
                      ... on PRISMIC_Market {
                        _meta {
                          uid
                        }
                      }
                    }
                  }
                  fields {
                    keyword
                    proof {
                      ... on PRISMIC_Publication {
                        type
                        title
                        date
                        summary
                        image
                        _meta {
                          uid
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      areas: allService_areas(sortBy: name_ASC) {
        edges {
          node {
            _meta {
              uid
            }
            name
            thumbnail
            hero {
              ... on PRISMIC_Service_hero {
                body {
                  ... on PRISMIC_Service_heroBodyMarket_hero {
                    type
                    primary {
                      market {
                        ... on PRISMIC_Market {
                          _meta {
                            uid
                          }
                        }
                      }
                      image1
                    }
                  }
                }
              }
            }
          }
        }
      }
      markets: allMarkets {
        edges {
          node {
            _meta {
              uid
            }
            services {
              service {
                ... on PRISMIC_Service_area {
                  _meta {
                    uid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

function serviceThumbnail(uid, service) {
  const generic =
    (service.hero.image && service.hero.image.IndustryServiceAreaThumbnail) || service.thumbnail;

  return !service.hero.body
    ? generic
    : service.hero.body.reduce((image, next) => {
        return next.primary.market._meta.uid !== uid
          ? image
          : (next.primary.image1 && next.primary.image1.IndustryServiceAreaThumbnail) || generic;
      }, generic);
}

function selectedServices(data, selectedMarket) {
  const industry =
    data.prismic.markets &&
    data.prismic.markets.edges.filter((edges) => edges.node._meta.uid === selectedMarket);

  const services = industry[0].node.services.map(({ service }) => {
    return data.prismic.areas.edges.reduce((prev, next) => {
      return next.node._meta.uid === service._meta.uid ? next : prev;
    });
  });

  return (
    services &&
    services.length &&
    services.map(({ node }) => ({
      uid: node._meta.uid,
      name: node.name[0].text,
      href: `/services/${node._meta.uid}`,
      image: serviceThumbnail(selectedMarket, node),
    }))
  );
}

function allServices(data) {
  return (
    data.prismic.areas &&
    data.prismic.areas.length &&
    data.prismic.areas.edges.map(({ node }) => ({
      uid: node._meta.uid,
      name: node.name[0].text,
      href: `/services/${node._meta.uid}`,
      image: node.thumbnail,
    }))
  );
}

export default function({ data }) {
  const { doc } = data.prismic;
  if (!doc) return <Redirect to="/services" noThrow />;

  const selectedMarket = typeof window !== 'undefined' && localStorage.getItem('selectedMarket');

  const industryServices = selectedMarket
    ? selectedServices(data, selectedMarket)
    : allServices(data);

  const hero =
    (doc.hero &&
      doc.hero.body &&
      doc.hero.body.reduce(
        (prev, next) =>
          next.primary.market._meta.uid === selectedMarket
            ? {
                heading: next.primary.heading1,
                text: next.primary.text1,
                image: next.primary.image1,
              }
            : prev,
        null,
      )) ||
    doc.hero;

  const segments =
    doc.segments &&
    doc.segments
      .map(({ segment }) => {
        const marketSegment = (segment.body &&
          segment.body.reduce(
            (prev, next) =>
              next.primary.market._meta.uid === selectedMarket
                ? {
                    priority: next.primary.priority,
                    title: next.primary.title1,
                    text: next.primary.text1,
                    rep: next.primary.rep1,
                    proofs: next.fields,
                    ctaTitle: segment.cta_title,
                    ctaUrl: segment.cta_url,
                  }
                : prev,
            null,
          )) || {
          priority: 0,
          title: segment.title,
          text: segment.text,
          rep: segment.rep,
          proofs: segment.proofs,
          ctaTitle: segment.cta_title,
          ctaUrl: segment.cta_url,
        };
        const { ctaTitle, ctaUrl, title, text, proofs, rep, priority = 0 } = marketSegment;
        const headline = title[0].text;

        return {
          id: doc._meta.id,
          contactButtonText: `Email Me` || 'Contact Us',
          contactButtonTo: (rep && rep.cta_link1) || '/contact',
          contactUsImage: rep && rep.photo,
          contactUsPerson: rep && rep.name,
          contactUsPhone: rep && rep.phone_number,
          contactUsTagline:
            (rep && rep.quote[0].text) || `Need more information about ${headline}?`,
          ctaTitle,
          ctaUrl,
          link: `#${slugify(headline, { remove: /[*+~.()'"!:@]/g, lower: true })}`,
          title: headline,
          text,
          proofs,
          priority,
        };
      })
      .sort((a, b) => b.priority - a.priority);
  return (
    <Layout
      title={doc.meta_title}
      description={doc.meta_description}
      image={hero.image && hero.image.url}
    >
      <ResponsiveContext.Consumer>
        {(size) => {
          const isSmall = size === 'small';

          return (
            <>
              <L2Hero
                backgroundURL={hero.image.url}
                size={size}
                titleHead={hero.heading[0].text}
                titleText={hero.text[0].text}
              />

              {isSmall ? (
                <ServicesPanelAccordion
                  Link={Link}
                  htmlSerializer={htmlSerializer}
                  segments={segments}
                />
              ) : (
                <>
                  <SecondaryNav links={segments} />
                  {segments &&
                    segments.map((segment, index) => (
                      <ServicesPanel
                        Link={Link}
                        background={segmentBackground(index)}
                        htmlSerializer={htmlSerializer}
                        key={segment.id}
                        {...segment}
                      />
                    ))}
                </>
              )}
              {industryServices && industryServices.length > 0 && (
                <Box
                  align="center"
                  direction="column"
                  pad={isSmall ? { horizontal: 'medium', vertical: 'xlarge' } : 'xlarge'}
                >
                  <CapabilitiesHeading
                    size={size}
                    level="5"
                    margin={{ top: 'none', bottom: 'large' }}
                  >
                    Explore More Services
                  </CapabilitiesHeading>
                  <CapabilitiesCarousel Link={Link} carouselContent={industryServices} />
                </Box>
              )}
            </>
          );
        }}
      </ResponsiveContext.Consumer>
    </Layout>
  );
}
